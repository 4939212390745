<template>
  <div>
    <!-- Sidebar -->
    <b-sidebar
      id="requirement-sidebar"
      title="Requirement Details"
      bg-variant="white"
      shadow="true"
      width="30vw"
      right
      backdrop
      :visible="isRequirementSidebarOpen"
      @hidden="isRequirementSidebarOpen = false"
    >
      <RequirementSidebar v-if="isRequirementSidebarOpen" class="mx-2" @updated="refresh" />
    </b-sidebar>

    <!-- Card Element -->
    <b-card-actions
      ref="requirementsCard"
      title="Requirements"
      no-body
      action-refresh
      action-collapse
      :show-loading="loading"
      @refresh="refresh"
    >
      <template #title>
        <b-button
          class="ml-1"
          variant="outline-success"
          size="sm"
          @click="addRequirement"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </template>
      <!-- Header -->
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">

          <!-- Sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="1"
            label-for="sortBySelect"
            label-class="mt-50 mr-50 pl-0"
            class="mb-0 text-nowrap"
            style="min-width: 20rem"
          >
            <b-input-group
              size="sm"
            >
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
          <!-- ./Sorting  -->

          <!-- Search -->
          <b-form-group
            label="Search"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            label-class="mt-50"
            class="mb-0"
            style="width: 30rem"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- ./Search -->
        </div>
      </b-card-body>

      <!-- Table -->
      <b-table
        responsive
        striped
        hover
        show-empty
        class="position-relative"
        :fields="fields"
        :items="requirementsData"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        @row-clicked="openRequirement"
      >
        <template #cell(display_id)="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>

        <template #cell(section)="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>

        <template #cell(object_text)="data">
          <span v-html="data.value" />
        </template>
      </b-table>

      <!-- Footer -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- Page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-class="mt-50"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
        <!-- ./Page length -->

        <!-- Pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="filter !== null ? filteredRows : totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      <!-- ./Pagination -->
      </b-card-body>
    </b-card-actions>
    <!-- Modals -->
    <RequirementsModalAdd
      :specification-id="$route.params.specId"
      @added="refresh"
    />
  </div>
</template>

<script>
import { BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import RequirementSidebar from '@/views/Requirement/RequirementSidebar.vue'
import RequirementsModalAdd from '@/views/RequirementsTableLegacy/modals/Add.vue'

export default {
  name: 'RequirementsCard',
  components: {
    BCardActions,
    BTable,
    BPagination,
    RequirementSidebar,
    RequirementsModalAdd,
  },
  props: {
    requirementsData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    fields: [
      { key: 'display_id', label: 'ID', sortable: true },
      { key: 'section', label: 'Section', sortable: true },
      { key: 'priority', label: 'Priority', sortable: true },
      { key: 'object_text', label: 'Requirement', sortable: true },
    ],
    perPage: 5,
    pageOptions: [3, 5, 10, 25, 50, 100],
    filteredRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    isRequirementSidebarOpen: false,
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    totalRows() {
      return this.requirementsData.length
    },
  },
  methods: {
    refresh() {
      this.$refs.requirementsCard.showLoading = true
      this.$store
        .dispatch('SpecificationPage/fetchSpecification', this.$route.params.specId)
        .then(() => {
          this.$refs.requirementsCard.showLoading = false
        })
    },
    openRequirement(row) {
      this.$store.dispatch('requirements/selectRequirement', row.id)
      this.isRequirementSidebarOpen = true
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredRows = filteredItems.length
      this.currentPage = 1
    },
    addRequirement() {
      this.$bvModal.show('add-requirement-modal')
    },
  },
}
</script>
